/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Main from "./Main.js";
import Project from "./Project.js";

// This is the App functional component it combines the Main and the Project components
// It also passes the parameters for the Project compnent to be used as props

function App() {
  return (
    <article>
      <Main />
      <h3 className="headerText">Projects</h3>
      <Project
        skill="React.js"
        githubLink="https://github.com/daple1997/DaniilPleskachWebsite.git"
      />
      <Project
        skill="HTML/CSS/JavaScript"
        githubLink="https://github.com/daple1997/PizzaPlace.git"
      />
      <Project
        skill="Java"
        githubLink="https://github.com/daple1997/Calgaryhacks2019.git"
      />
      <Project
        skill="JavaScript"
        githubLink="https://github.com/daple1997/JavaScript/blob/master/Randomized%20list.git"
      />

      {/* <Footer /> */}
      <br />
    </article>
  );
}

export default App;
