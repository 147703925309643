import React from "react";
//This is the functional component of the App
//Props are received and make to describe a skill
// and to give a link to the github
function Project(prop) {
  return (
    <div>
      <table className="projectElement">
        <tbody>
          <tr>
            <td>
              <strong>{prop.skill}</strong>
            </td>
            <td>
              :<a href={prop.githubLink}>{prop.githubLink}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Project;
