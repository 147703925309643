import React from "react";
import Email from "./images/Email.JPG";
import LinkedIn from "./images/LinkedIn.JPG";
import GitHub from "./images/GitHub.JPG";
import ContactLink from "./ContactLink";

function Main() {
  return (
    <main className="mainClass">
      <h1 className="headerText">Daniil Pleskach</h1>
      <div className="businessCard">
        <h1>Software developer</h1>
        <p>
          <strong>Location</strong>: Edmonton, Alberta, Canada
        </p>
        <p>
          <strong>Tel</strong>: (587) 432-1541
        </p>

        <p>
          <ContactLink href="mailto:daple1997@gmail.com" image={Email} />

          <ContactLink
            href="https://www.linkedin.com/in/daniil-pleskach-53621815a/"
            image={LinkedIn}
          />

          <ContactLink href="https://github.com/daple1997" image={GitHub} />
        </p>
      </div>
    </main>
  );
}
export default Main;
