import React from "react";

function ContactLink(props) {
  return (
    <a href={props.href} className="contact-link">
      <img src={props.image} alt={props.href} />
    </a>
  );
}

export default ContactLink;
